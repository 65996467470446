<template>
  <div>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <!-- Media -->
        <b-media class="mb-2">
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src="fetchData.logo"
              :text="avatarText(fetchData.name)"
              size="90px"
              rounded
            />
          </template>
          <h4 class="mb-1">
            Logo
          </h4>
          <div class="d-flex flex-wrap">
            <b-button
              variant="primary"
              @click="$refs.refInputEl.click()"
            >
              <input
                ref="refInputEl"
                type="file"
                class="d-none"
                @input="inputImageRenderer"
              >
              <span class="d-none d-sm-inline">Update Logo</span>
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
            <b-button v-if="fetchData.logo_file"
              variant="outline-secondary"
              class="ml-1"
            >
              <span @click="clearImage" class="d-none d-sm-inline">Clear</span>
            </b-button>
          </div>
        </b-media>

        <!-- User Info: Input Fields -->

        <b-row>

          <!-- Field: Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="fetchData.name"
                  :state="getValidationState(validationContext)"
                  trim
                />
              </b-form-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="fetchData.contact_email"
                  type="email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>

              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Phone -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="fetchData.contact_phone"
              />
            </b-form-group>
          </b-col>

          <!-- Field: domain -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="domain"
              rules="url"
            >
              <b-form-group
                label="Website"
                label-for="domain"
              >
                <b-form-input
                  id="domain"
                  v-model="fetchData.domain"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Address -->
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Address"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="fetchData.contact_address"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <!-- Field: Description -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="fetchData.description"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <!-- Field: approval 
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Is Approved"
              label-for="is-approved"
              label-class="mb-1"
            >
              <b-form-radio-group
                id="is-approved"
                v-model="fetchData.is_approved"
                :options="approveOptions"
                value="0"
              />
            </b-form-group>
          </b-col>-->

          <!-- Field: archive 
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Is Archived"
              label-for="is-archived"
              label-class="mb-1"
            >
              <b-form-radio-group
                id="is-archived"
                v-model="fetchData.is_archived"
                :options="archiveOptions"
                value="0"
              />
            </b-form-group>
          </b-col>-->

        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :to="{name: 'organization-list'}"
        >
          Cancel
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormRadioGroup, BFormTextarea, BFormInvalidFeedback
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useList from '../list/useList'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email, url } from '@validations'
import store from '@/store'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormRadioGroup,
    BFormTextarea,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  props: {
    fetchData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      url,
    }
  },
  methods: {

    onSubmit() {
      store.dispatch('app-org/editData', this.fetchData)
        .then((response) => {
          if(response.data.status == 1 || response.data.success){
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully updated!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
          }
          else if(response.data.status == 0){
              this.$swal({
                    icon: 'error',
                    title: response.data.message, //JSON.stringify(response.data.errors),
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  })
            }
      })
    }
  },
  setup(props, {emit}) {
    const logo_file = props.fetchData.logo;
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(props.fetchData)

    const clearImage = () => {
      props.fetchData.logo_file = '';
      props.fetchData.logo = logo_file;
    }


    const approveOptions = [
      { text: 'No', value: 0 },
      { text: 'Yes', value: 1 },
    ]

    const archiveOptions = [
      { text: 'No', value: 0 },
      { text: 'Yes', value: 1 },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.fetchData.logo_file = base64
      props.fetchData.logo = base64
    })

    return {
      avatarText,
      approveOptions,
      archiveOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      clearImage,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
